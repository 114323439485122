var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-publish-container" },
    [
      _c("Title", { attrs: { name: "参与的竞标" } }),
      _c(
        "div",
        { staticClass: "tabel-content" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": {
                  background: "#f5f5f5",
                  "font-weight": 500,
                  color: "#666",
                  padding: "12 5px",
                },
                data: _vm.tableData,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "bid_name", label: "标的名称", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "price", width: "120", label: "标的价格(元)" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taxRate",
                  width: "100",
                  align: "center",
                  label: "税率(%)",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "contacts", width: "100", label: "联系人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", width: "120", label: "手机号码" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "delivery_address",
                  label: "收货地址",
                  width: "180",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "start_time", label: "开始时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "end_time", label: "结束时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deliveryDate",
                  label: "交货时间",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toDetail(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.pageManage.total
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      total: _vm.pageManage.total,
                      "page-size": _vm.pageManage.pageSize,
                      "current-page": _vm.pageManage.pageNo,
                    },
                    on: { "current-change": _vm.changeCurrentPage },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }