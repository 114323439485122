<template>
  <div class="my-publish-container">
    <Title name='参与的竞标'></Title>
    <div class="tabel-content">
      <el-table
        :header-cell-style="{'background':'#f5f5f5','font-weight': 500,'color':'#666','padding':'12 5px'}"
        :data="tableData"
        style="width: 100%">
        <el-table-column prop="bid_name"
                         label="标的名称"
                         width="180">
        </el-table-column>
        <el-table-column prop="price"
                         width="120"
                         label="标的价格(元)">
        </el-table-column>
        <el-table-column prop="taxRate"
                         width="100"
                         align="center"
                         label="税率(%)">
        </el-table-column>
        <el-table-column prop="contacts"
                         width="100"
                         label="联系人">
        </el-table-column>
        <el-table-column prop="phone"
                         width="120"
                         label="手机号码">
        </el-table-column>
        <el-table-column prop="delivery_address"
                         label="收货地址"
                         width="180">
        </el-table-column>
        <el-table-column prop="start_time"
                         label="开始时间"
                         width="160">
        </el-table-column>
        <el-table-column prop="end_time"
                         label="结束时间"
                         width="160">
        </el-table-column>
        <el-table-column prop="deliveryDate"
                         label="交货时间"
                         width="160">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          align="center">
          <template slot-scope="scope">
            <el-button size="small" type="text" @click="toDetail(scope.row.id)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination" v-if="pageManage.total">
        <el-pagination
          background layout="prev, pager, next"
          :total='pageManage.total'
          :page-size='pageManage.pageSize'
          :current-page="pageManage.pageNo"
          @current-change="changeCurrentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getParticipateCompetitive,
  withdrawProduct
} from '../../api';
import Title from '../../component/Title'
export default {
  components: {
    Title
  },
  data() {
    return {
      productPictureManage: {
        dialogImageUrl: '',
        dialogVisible: false
      },
      pageManage: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      tableData: []
    }
  },
  created() {
    this.$store.commit('changeMenuType', 'SELL');
    this.getParticipateCompetitive();
  },
  filters: {
    getApprvStatus(val) {
      switch(val) {
        case 'A':
          return '待审核';
          break;
        case 'P':
          return '已通过';
          break;
        case 'F':
          return '已拒绝';
          break;
      }
    }
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: '/home/TenderDetails',
        query: {
            id
        }
      })
    },
    getParticipateCompetitive() {
      getParticipateCompetitive({
        ...this.pageManage,
      }).then(res => {
        this.tableData = res.data.list;
        this.pageManage.total = res.data.total;
      });
    },
    changeCurrentPage(num){
      this.pageManage.pageNo = num;
      this.getParticipateCompetitive();
    }
  }
}
</script>

<style lang="less">
.my-publish-container {
  background: #fff;

  .filter-content {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .tabel-content {
    padding: 10px;
    box-sizing: border-box;
  }
  .pagination {
    padding: 20px;
    box-sizing: border-box;
    text-align: right;
  }
}
</style>
